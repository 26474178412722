@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap");
html {
  height: 100%;
  background-color: theme("colors.stone.100");
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  color: theme("colors.mainBlack");
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

button:hover {
  background-color: theme("colors.hoverBlue");
}

.numberCircle {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1rem;
  text-align: center;
}

/* Medium */
@media (min-height: 600px) and (min-width: 500px) {
  .numberCircle {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 8px;
    text-align: center;
  }
}

/* TallAndWide */
@media (min-height: 600px) and (min-width: 800px) {
  .numberCircle {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 10px;
    text-align: center;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}
